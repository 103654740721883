import React, { useState } from 'react';
import { FormattedNumber, FormattedMessage } from 'react-intl';

import DataRow from 'components/Shared/DataRow/DataRow';
import Hr from 'components/Shared/Hr/Hr';
import InvoiceStatus from 'components/Shared/InvoiceStatus/InvoiceStatus';
import CpPopover from 'components/Shared/Popover/CpPopover';
import SegmentIO from 'reporting/SegmentIO';
import { colors, fontSize } from 'styles/cp';

export interface InvoiceDataRowsProps {
  currency?: string;
  isPartiallyPaid?: boolean;
  isFullyPaid?: boolean;
  invoiceAmount?: number;
  achOnlineConvenienceFeeAmount?: number;
  achOnlineConvenienceFeeEnabled?: boolean;
}

const InvoiceDataRows = ({
  currency,
  isPartiallyPaid,
  isFullyPaid,
  invoiceAmount,
  achOnlineConvenienceFeeEnabled = false,
  achOnlineConvenienceFeeAmount = 0,
}: InvoiceDataRowsProps) => {
  const isMobile = typeof window === 'object' ? window.innerWidth < 845 : false;
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [hasTooltipBeenHoveredOnce, setHasTooltipBeenHoveredOnce] = useState(false);
  const totalAmount =
    invoiceAmount && achOnlineConvenienceFeeAmount
      ? invoiceAmount + achOnlineConvenienceFeeAmount
      : achOnlineConvenienceFeeAmount;
  const onMouseEnter = () => {
    setIsTooltipOpen(true);
    !hasTooltipBeenHoveredOnce &&
      SegmentIO.transactionEngaged({
        ui_object: 'tooltip',
        ui_action: 'hovered',
        ui_object_detail: 'convenience_fee_tooltip',
        ui_access_point: 'transaction_flow',
      });
    setHasTooltipBeenHoveredOnce(true);
  };
  const shouldShowMaipLineItem =
    !!achOnlineConvenienceFeeEnabled && achOnlineConvenienceFeeAmount > 0 && totalAmount;
  return (
    <>
      {isPartiallyPaid && <InvoiceStatus idValue="INVOICE_SUMMARY_PARTIALLY_PAID" />}
      {shouldShowMaipLineItem && (
        <>
          <span id="maip-tooltip-w">
            <li aria-label="data row item">
              <div className="space-between flex-row">
                <span
                  className="label left"
                  onMouseEnter={onMouseEnter}
                  onTouchStart={onMouseEnter}
                  onMouseLeave={() => setIsTooltipOpen(false)}
                >
                  <span className="borderBottom">
                    <FormattedMessage
                      id="MAIP_ONLINE_CONVENIENCE_FEE"
                      defaultMessage="Online convenience fee"
                    />
                  </span>
                </span>
                <span className="label right">
                  <FormattedNumber
                    value={achOnlineConvenienceFeeAmount}
                    style="currency"
                    currency={currency}
                  />
                </span>
              </div>
            </li>
          </span>
          {isFullyPaid && (
            <>
              <Hr marginTop={10} marginBottom={0} borderColor={colors.offWhite} opacity={1} />
              <DataRow
                idValue="ACH_ONLINE_CONVENIENCE_FEE_INVOICE_TOTAL"
                defaultMessage="Total"
                bold={true}
                color={colors.black}
                value={<FormattedNumber value={totalAmount} style="currency" currency={currency} />}
              />
              <Hr marginTop={10} marginBottom={0} borderColor={colors.offWhite} opacity={1} />{' '}
            </>
          )}
        </>
      )}
      <CpPopover
        className="cp-tooltip-wrapper"
        trigger="hover"
        innerClassName="cp-tooltip"
        placement={isMobile ? 'bottom' : 'top-end'}
        target={isMobile ? 'invoice-more-info' : 'maip-tooltip-w'}
        isOpen={isTooltipOpen}
      >
        <FormattedMessage
          id="MAIP_TOOLTIP_MSG"
          defaultMessage="This is just a fee for paying online. You can still pay offline without paying this fee."
        />
      </CpPopover>
      <style jsx>{`
        .space-between {
          display: flex;
          justify-content: space-between;
        }

        .flex-row {
          flex-direction: row;
          margin: 6px 0p;

          .label {
            cursor: text;
            font-size: ${fontSize.xs};
            color: ${colors.gray};
            display: flex;
            font-family: AvenirNextforINTUIT-Regular;
          }

          .right {
            text-align: right;
            font-family: AvenirNextforINTUIT-Medium;
            margin-left: 5px;
          }

          .left {
            text-align: left;
            border-bottom: dotted;
            cursor: pointer;
          }
        }

        li {
          display: block;
        }
      `}</style>
    </>
  );
};

export default InvoiceDataRows;
