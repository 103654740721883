import React, { Fragment } from 'react';

import {
  calculateDiscountAmount,
  calculateShippingAmount,
  calculateSubTotalAmount,
} from 'businessLogic/server/scsToIcnAdapter';
import InvoiceDataRows from 'components/Core/Invoice/InvoiceDataRows/InvoiceDataRows';
import InvoiceLineItems from 'components/Core/Invoice/InvoiceLineItems/InvoiceLineItems';
import InvoiceSummary from 'components/Core/Invoice/Summary/InvoiceSummary';
import { useIsMobileScreen } from 'hooks/useIsMobile';
import { LineItems } from 'pages/subscription.page';
import { breakpoints, colors } from 'styles/cp';
import { FeatureFlags } from 'types/FeatureFlags';
export type InvoiceInfoProps = {
  invoiceNumber?: string;
  invoiceDueDate?: string;
  currency?: string;
  featureFlags?: FeatureFlags;
  shouldShowInvoiceLineItems?: boolean;
  useRedesign: boolean;
  showModal?: any;
  pdfUrl?: string;
  fetchPDFStatus?: string;
  token?: string;
  gratuityValue: number;
  isPartiallyPaid?: boolean;
  isFullyPaid?: boolean;
  invoiceLineItems?: LineItems;
  invoiceTaxAmount?: number;
  invoiceAmount: number;
  achOnlineConvenienceFeeAmount?: number;
  achOnlineConvenienceFeeEnabled?: boolean;
};

const InvoiceInfo = (props: InvoiceInfoProps) => {
  const {
    gratuityValue,
    invoiceNumber,
    invoiceDueDate,
    currency,
    invoiceAmount,
    useRedesign,
    invoiceLineItems,
    invoiceTaxAmount,
    shouldShowInvoiceLineItems,
    isPartiallyPaid,
    isFullyPaid,
    achOnlineConvenienceFeeEnabled,
    achOnlineConvenienceFeeAmount,
  } = props;

  const discountAmount = calculateDiscountAmount(invoiceLineItems);
  const subTotalAmount = calculateSubTotalAmount(invoiceLineItems);
  const shippingAmount = calculateShippingAmount(invoiceLineItems);

  const isMobile = useIsMobileScreen(768);
  const hrColor = isMobile ? colors.gray06 : colors.offWhite;

  return (
    <Fragment>
      <style jsx>{`
        .invoice-info {
          padding: 0px;
          margin: ${useRedesign ? '0 14px' : '0'};

          @media (max-width: ${breakpoints.md}) {
            max-width: 400px;
            margin: 5px auto;
          }
          @media (max-width: ${breakpoints.sm}) {
            max-width: 576px;
          }
        }
      `}</style>

      <ul className="invoice-info" aria-label="invoice info list">
        <InvoiceSummary
          invoiceNumber={invoiceNumber}
          invoiceDueDate={invoiceDueDate}
          invoiceAmount={invoiceAmount}
          shouldShowInvoiceLineItems={shouldShowInvoiceLineItems}
          currency={currency}
          gratuityValue={gratuityValue}
          hrColor={hrColor}
        />
        {shouldShowInvoiceLineItems && (
          <InvoiceLineItems
            invoiceLineItems={invoiceLineItems}
            currency={currency}
            subTotalAmount={subTotalAmount}
            discountAmount={discountAmount}
            shippingAmount={shippingAmount}
            invoiceTaxAmount={invoiceTaxAmount}
            hrColor={hrColor}
          />
        )}
        <InvoiceDataRows
          currency={currency}
          isPartiallyPaid={isPartiallyPaid}
          isFullyPaid={isFullyPaid}
          invoiceAmount={invoiceAmount}
          achOnlineConvenienceFeeAmount={achOnlineConvenienceFeeAmount}
          achOnlineConvenienceFeeEnabled={achOnlineConvenienceFeeEnabled}
        />
      </ul>
    </Fragment>
  );
};

InvoiceInfo.defaultProps = {
  gratuityValue: 0,
};

export default InvoiceInfo;
